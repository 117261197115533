import { createApp } from 'vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import router from './router'
import store from './store'
import App from './App.vue'
import './permission'
import '@/style/index.css'
// if (process.env.NODE_ENV == 'development') {
//   // import mockRequest from '@/mock'
//   console.log('use-mock')
//   const { mockRequest } = require('@/mock')
//   mockRequest()
// }

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus, { locale: zhCn })
app.use(router)
app.use(store)
app.mount('#app')

<template>
  <div style="height: 60px;color: rgb(144, 147, 153);background: #f6f6f6;line-height: 60px;position: fixed;left: 0;right: 0;text-align: center;bottom: 0;z-index: 999;">
    ©2022 Powered by PayRu OS V{{ version }}
  </div>
</template>

<script setup>
const version = process.env.VUE_APP_VERSION
</script>

<style scoped></style>

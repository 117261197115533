import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import Layout from '@/layout/index.vue'
const routes = [
  {
    path: '/',
    // TODO 这里在permission.ts里跳转时根据角色来重定向
    redirect: '/dashboard',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'account',
        component: () => import('@/pages/account/index.vue'),
        meta: {
          activeMenu: '/account'
        }
      },
      {
        path: '/certification',
        name: 'Certification',
        component: () => import('@/pages/certification/index.vue')
      },
      {
        path: '/setting',
        name: 'Selfaccount',
        component: () => import('@/pages/selfaccount/index.vue')
      },
      {
        path: '/finance',
        name: 'finance',
        component: () => import('@/pages/finance/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login/index.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/login/register.vue')
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('@/pages/login/reset.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        left: 0,
        top: 0
      }
    }
  }
})
export default router

/**
 * 存储数据
 */
export const setItem = (key: string, value: string | object) => {
  // 获取的value可以分为两种情况
  // 1、基本数据类型
  // 2、复杂数据类型
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.localStorage.setItem(key, value)
}
/**
 * 获取数据
 */
export const getItem = (key: string) => {
  const data: string | null = window.localStorage.getItem(key)
  if (data) {
    try {
      return JSON.parse(data)
    } catch (err) {
      return data
    }
  } else {
    return ''
  }
}
/**
 * 删除指定数据
 */
export const removeItem = (key: string) => {
  window.localStorage.removeItem(key)
}
/**
 * 删除所有数据
 */
export const removeAllItem = () => {
  window.localStorage.clear()
}

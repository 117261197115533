import axios from 'axios'
import store from '@/store'
import { ElNotification } from 'element-plus'
import { removeAllItem } from '@/utils/storage'
import router from '@/router'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  timeout: 30000 // request timeout
})
service.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

// 不校验token的接口
const whiteList = ['/sys/login']
service.interceptors.request.use((config) => {
  const params = new URLSearchParams(config.data)
  params.append('version', process.env.VUE_APP_VERSION)
  const base_url: string = window.location.host
  params.append('host', base_url)
  if (config.url && whiteList.indexOf(config.url) === -1) {
    if (store.getters.token) {
      params.append('token', store.getters.token)
    }
  }
  config.data = params.toString()
  return config
})

service.interceptors.response.use(
  (response) => {
    console.log('response', response)
    const data = response.data
    if (data.code == 0 || data.code == 200) {
      return response
    } else {
      if (data.code == 401) {
        if (store.getters.token) {
          ElNotification.warning('登录超时')
          removeAllItem()
          store.commit('user/setToken', '')
          router.push('/login')
        }
      }
      return response
    }
  },
  (error) => {
    // Do something with response error
    console.log(error) // for debug
    console.log(error.response) // for debug
    console.log(error.response.status) // for debug
    if (error.response && error.response.status == 401) {
      if (store.getters.token) {
        ElNotification.warning('登录超时')
        removeAllItem()
        store.commit('user/setToken', '')
        router.push('/login')
      }
    }
    return Promise.reject(error)
  }
)
export default service

import request from '@/utils/request'
/**
 * 登录
 */
export function adminLogin(data: object) {
  return request({
    url: '/admin/admin/login',
    method: 'POST',
    data
  })
}

export function adminInfo(data: object) {
  return request({
    url: '/admin/admin/info',
    method: 'POST',
    data
  })
}

export function adminLogout(data: object) {
  return request({
    url: '/admin/admin/login-out',
    method: 'POST',
    data
  })
}

export function adminRegister(data: object) {
  return request({
    url: '/admin/admin/register',
    method: 'POST',
    data
  })
}

export function adminResetPassword(data: object) {
  return request({
    url: '/admin/admin/reset-password',
    method: 'POST',
    data
  })
}

export function getSmsCode(data: object) {
  return request({
    url: '/admin/sms/verification-code',
    method: 'POST',
    data
  })
}

export function getAccountQuestionList(data: object) {
  return request({
    url: '/admin/user-foreign-account/questionnaire',
    method: 'POST',
    data
  })
}

export function changePhone(data: object) {
  return request({
    url: '/admin/admin/reset-mobile',
    method: 'POST',
    data
  })
}

export function getEmailCode(data: object) {
  return request({
    url: '/admin/email/verification-code',
    method: 'POST',
    data
  })
}

export function changeEmail(data: object) {
  return request({
    url: '/admin/admin/reset-email',
    method: 'POST',
    data
  })
}
export function initOss(data: object) {
  console.log('initOss')
  return request({
    url: '/admin/site/tas',
    method: 'POST',
    data
  })
}

export function OCRIdentify(data: object) {
  return request({
    url: '/admin/ocr/identify-card',
    method: 'POST',
    data
  })
}

export function getProductTypeList(data: object) {
  return request({
    url: '/admin/product/options-list',
    method: 'POST',
    data
  })
}

export function getCountryOptionList(data: object) {
  return request({
    url: '/admin/country/options-list',
    method: 'POST',
    data
  })
}

export function getRate(data: object) {
  return request({
    url: '/admin/exchange-rate/get-exchange-rate',
    method: 'POST',
    data
  })
}

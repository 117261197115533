<template>
  <router-view />
</template>

<script lang="ts">
export default {
  name: 'App',
  mounted() {
    console.log('mounted')
    if (process.env.ENV == 'staging') {
      //   正式环境禁止F12调试和右键菜单
      document.onkeydown = (event) => {
        const e = event || window.event
        if (e && e.keyCode == 123) {
          e.returnValue = false
          return false
        }
      }
      document.oncontextmenu = function (event) {
        const e = event || window.event
        if (e.target) {
          try {
            const target = e.target as HTMLElement
            const the = target.tagName
            return (
              (the == 'INPUT' && the.toLowerCase() == 'text') ||
              the == 'TEXTAREA'
            )
          } catch (e) {
            return false
          }
        }
      }
      // 无限debug
      const ban = function () {
        setInterval(() => {
          Function('debugger')()
        }, 50)
      }
      try {
        ban()
      } catch (err) {
        console.log('e', err)
      }
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>

<template>
  <div class="navbar-box">
    <div class="logo-img">
      <el-image
        :src="require('@/assets/static/img/logo-icon.png')"
        class="status-logo"
      ></el-image>
    </div>
    <div class="operate-box">
      <div
        v-if="activeMenu != '/certification'"
        class="operate-item"
        :class="activeMenu == '/account' ? 'active' : ''"
        @click="goPage('account')"
      >
        账户
      </div>
      <div
        v-if="activeMenu != '/certification'"
        class="operate-item"
        :class="activeMenu == '/finance' ? 'active' : ''"
        @click="goPage('finance')"
      >
        交易明细
      </div>
      <div
        v-if="activeMenu != '/certification'"
        class="operate-item"
        :class="activeMenu == '/setting' ? 'active' : ''"
        @click="goPage('setting')"
      >
        我的账户
      </div>
      <div class="operate-item" @click="logout">退出登录</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'
import { adminInfo, adminLogout } from '@/api/sys'
import { ElMessage, ElNotification } from 'element-plus'
import { removeAllItem, setItem } from '@/utils/storage'
import router from '@/router'
import { ElMessageBox } from 'element-plus'

const route = useRoute() // 当前页面路由 => $route
const activeMenu = computed(() => {
  const { meta, path } = route
  if (meta && meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})

adminInfo({})
  .then((xhr) => {
    if (xhr.data.code == 0) {
      setItem('role', xhr.data.data.role)
      // setItem('u_code', xhr.data.data.u_code)
      setItem('verify_step', xhr.data.data.verify_step)
      setItem('verify_status', xhr.data.data.verify_status)
      setItem('phone', xhr.data.data.phone)
      setItem('email', xhr.data.data.email)
      setItem('merchant_name', xhr.data.data.merchant_name)
      setItem('company_name', xhr.data.data.company_name)
      setItem(
        'is_bind_withdraw_account',
        xhr.data.data.is_bind_withdraw_account
      )
    } else {
      ElMessage.warning(xhr.data.message || '网络错误')
    }
  })
  .catch()

const logout = () => {
  ElMessageBox.confirm('确定退出登录？', '提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  })
    .then(() => {
      adminLogout({})
        .then((res) => {
          console.log('logout-res', res)
          if (res.data.code == 0) {
            removeAllItem()
            store.commit('user/setToken', '')
            ElNotification.success('退出登录成功')
            setTimeout(() => {
              router.push('/login')
            }, 1000)
          } else {
            ElNotification.warning(res.data.message || '网络错误')
          }
        })
        .catch((err) => {
          console.log('logout-err', err)
          ElNotification.warning('网络错误')
        })
    })
    .catch(() => {
      console.log('cancel')
    })
}
const goPage = (path: string) => {
  switch (path) {
    case 'account':
      router.push('/dashboard')
      break
    case 'finance':
      router.push('/finance')
      break
    case 'setting':
      router.push('/setting')
      break
    default:
      break
  }
}
</script>

<style scoped>
.navbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 1170px;
  padding-right: 12px;
  padding-left: 3px;
  box-sizing: border-box;
  margin: 0 auto;
}
.logo-img {
  width: 100px;
  height: 66px;
}
.operate-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.operate-box .operate-item {
  margin-left: 40px;
  color: #454545;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.operate-box .operate-item.active {
  position: relative;
}
.operate-box .operate-item.active:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  height: 2px;
  background: #176afe;
}
.logo-img {
  width: 100px;
  height: 66px;
}
</style>

import router from './router'
import store from '@/store'
import { getItem } from '@/utils/storage'

const whiteList = [
  '/login',
  '/register',
  '/reset'
  // '/dashboard',
  // '/finance',
  // '/setting',
  // '/certification'
]

router.beforeEach((to, from, next) => {
  // next()
  // return
  console.log('to', to.path)
  if (store.getters.token) {
    // TODO 这里根据角色重定向
    console.log('token', store.getters.token)
    console.log('role===user', getItem('role') === 'user')
    console.log('verify_step', getItem('verify_step'))
    console.log('path', to.path)
    if (getItem('role') === 'user') {
      if (
        (getItem('verify_step') == 'company' ||
          getItem('verify_step') == 'foreign_account') &&
        to.path !== '/certification'
      ) {
        next('/certification')
      } else {
        console.log('goNext')
        if (
          to.path == '/certification' &&
          getItem('is_bind_withdraw_account') == 1
        ) {
          next('/')
        } else {
          next()
        }
      }
    } else {
      if (to.path === '/login' || to.path === '/') {
        next('/')
      } else {
        console.log('goNext')
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})

import { adminLogin } from '@/api/sys'
// import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN } from '@/constant'
import store from '@/store'
import router from '@/router'

interface state {
  token: string
  phone: string
  verify_status: string
  verify_step: string
  company_name: string
  merchant_name: string
}

export default {
  namespaced: true, // 表示是单独的模块，不会被合并到别的模块中
  state: () => ({
    token: getItem(TOKEN) || '',
    phone: getItem('phone') || '',
    verify_status: getItem('verify_status') || '',
    verify_step: getItem('verify_step') || '',
    company_name: getItem('company_name') || '',
    merchant_name: getItem('merchant_name') || ''
  }),
  mutations: {
    setToken(state: state, token: string) {
      state.token = token
      setItem('TOKEN', token)
    }
  },
  actions: {
    login(context: any, userInfo: Record<string, any>) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        adminLogin({
          username,
          password
          // password: md5(password) // MD5加密
        })
          .then((data) => {
            console.log('this', this)
            store.commit('user/setToken', data.data.data.token)
            resolve(data.data.data.token)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    logout() {
      store.commit('user/setToken', '')
      removeAllItem()
      // TODO 清理权限相关
      router.push('/login')
    }
  }
}
